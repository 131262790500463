import React from 'react'
import {Icon, TextStyle} from "@shopify/polaris";
import {
  EditMajor, DuplicateMinor, DeleteMajor, CircleDisabledMajor, CircleTickMajor, ReturnMinor,
} from '@shopify/polaris-icons';
import styled from 'styled-components'

const ActionBlock = styled.div`
   display: flex;
  
  .Polaris-Icon {
    margin: 0 10px 0 0 
  }
`;

const actionsIcons = {
  edit: EditMajor,
  duplicate: DuplicateMinor,
  disable: CircleDisabledMajor,
  enable: CircleTickMajor,
  delete: DeleteMajor,
  restore: ReturnMinor,
};

const OfferActionItem = ({actionType, actionTitle}) => {
  return (
    <ActionBlock>
      <Icon color={actionType === 'delete' ? "critical" : "base"} source={actionsIcons[actionType]}/>
      <TextStyle variation={actionType === 'delete' && 'negative'}>{actionTitle}</TextStyle>
    </ActionBlock>
  )
};

export default OfferActionItem;
